// userReducer.js
import {
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAILURE,
    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_FAILURE,
    NOTIFICATION_COUNT,
    ONLINE_FRIENDS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
  } from '../actions/userAction';
    
  const initialState = {
    registering: false,
    loggingIn: false,
    loading: false, 
    isLoggedIn: null,
    successMessage: '',
    user: {},
    errors: {},
    notification_count: {},
    online_friends: [],
  };
    
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
                successMessage: '',
                errors: {},
            };
        case LOGIN_USER_SUCCESS:
            localStorage.setItem('access_token', action.payload.token);
            return {
                ...state,
                loading: false,
                loggingIn: false,
                isLoggedIn: true,
                successMessage: action.payload.message,
                user: action.payload.user,
                errors: {},
            };
        case LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                loggingIn: false,
                successMessage: '',
                errors: action.payload.errors,
            };
        case REGISTER_USER_REQUEST:
            return {
                ...state,
                registering: true,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                registering: false,
                loading: false,
                successMessage: action.payload.message,
                errors: {},
            };
        case REGISTER_USER_FAILURE:
            return {
                ...state,
                registering: false,
                loading: false,
                successMessage: '',
                errors: action.payload.errors,
            };
        case USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
                successMessage: '',
                errors: {},
            };
        case USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                successMessage: action.payload.message,
                user: action.payload.user,
                errors: {},
            };
        case USER_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                successMessage: '',
                errors: { message: action.payload },
            };
        case VERIFY_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
                successMessage: '',
                errors: {},
            };
        case VERIFY_TOKEN_SUCCESS:
            localStorage.setItem('access_token', action.payload.token);
            return {
                ...state,
                loading: false,
                loggingIn: false,
                isLoggedIn: false,
                successMessage: action.payload.message,
                errors: {},
            };
        case VERIFY_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                loggingIn: false,
                successMessage: '',
                errors: { message : action.payload },
            };
        case NOTIFICATION_COUNT:
            return {
                ...state,
                notification_count: action.payload.notification_count
            };
        case ONLINE_FRIENDS:
            return {
                ...state,
                online_friends: action.payload.friends.friends
            };
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload.message,
                errors: {},
                user: action.payload.user,
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: action.payload.errors,
            };
        default:
            return state;
    }
  };
    
  export default userReducer;
  
  