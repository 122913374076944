import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../Logo/Logo';
import LeftArrowIcon from '../../assets/icons/LeftArrowIcon';
import { AiOutlineDashboard, AiOutlineHeart, AiOutlineMessage, AiOutlineUserAdd } from 'react-icons/ai';
import { MdOutlineGroups3 } from "react-icons/md";
import { useSelector } from 'react-redux';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { pathname } = location;

const notification_count = useSelector(state => state.user.notification_count);

return (
    <aside
		className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
			sidebarOpen ? 'translate-x-0' : '-translate-x-full'
		}`}
    >
		<div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
			<NavLink to="/dashboard">
				<Logo />
			</NavLink>

			<button
				onClick={() => setSidebarOpen(!sidebarOpen)}
				aria-controls="sidebar"
				aria-expanded={sidebarOpen}
				className="block lg:hidden"
			>
				<LeftArrowIcon />
			</button>
		</div>

		<div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
			<nav className="py-4 px-4 lg:px-6">
				<div>
					<ul className="mb-6 flex flex-col gap-1.5">
						<NavLink
							to="/dashboard"
							className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
								(pathname === '/dashboard') &&
								'bg-graydark dark:bg-meta-4'
							}`}
							onClick={() => setSidebarOpen(false)}
						>
							<AiOutlineDashboard />
							Dashboard
						</NavLink>
						<NavLink
							to="/chats/"
							className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
								(pathname === '/chats/') &&
								'bg-graydark dark:bg-meta-4'
							}`}
							onClick={() => setSidebarOpen(false)}
						>
							<AiOutlineMessage />
							Chats
							{
								notification_count?.direct_messages && Object.values(notification_count?.direct_messages)?.reduce((a, b) => a + b, 0) > 0 && <span className="rounded bg-primary py-1 px-2.5 text-xs font-medium text-white">{ Object.values(notification_count?.direct_messages).reduce((a, b) => a + b, 0) }</span>
							}
						</NavLink>
						<NavLink
							to="/requests"
							className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
								(pathname === '/requests') &&
								'bg-graydark dark:bg-meta-4'
							}`}
							onClick={() => setSidebarOpen(false)}
						>
							<AiOutlineUserAdd />
							Requests
							{
								notification_count?.requests && notification_count?.requests > 0 ? <span className="rounded bg-primary py-1 px-2.5 text-xs font-medium text-white">{ notification_count?.requests }</span> : ''
							}
						</NavLink>

						<NavLink
							to="/groups/"
							className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
								(pathname === '/groups/') &&
								'bg-graydark dark:bg-meta-4'
							}`}
							onClick={() => setSidebarOpen(false)}
						>
							<MdOutlineGroups3 />
							Groups
							{
								notification_count?.channel_messages && Object.values(notification_count?.channel_messages)?.reduce((a, b) => a + b, 0) > 0 && <span className="rounded bg-primary py-1 px-2.5 text-xs font-medium text-white">{ Object.values(notification_count?.channel_messages).reduce((a, b) => a + b, 0) }</span>
							}
						</NavLink>
						<NavLink
							to="/liked_by/"
							className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
								(pathname === '/liked_by/') &&
								'bg-graydark dark:bg-meta-4'
							}`}
							onClick={() => setSidebarOpen(false)}
						>
							<AiOutlineHeart />
							Liked By
							{
								notification_count?.likes && notification_count?.likes > 0 ? <span className="rounded bg-primary py-1 px-2.5 text-xs font-medium text-white">{ notification_count?.likes }</span> : ''
							}
						</NavLink>
					</ul>
				</div>
			</nav>
		</div>
    </aside>
  );
};

export default Sidebar;
