import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../pages/Auth/Login/Login';
import DefaultLayout from '../layout/DefaultLayout';
import NotFound from '../pages/Errors/NotFound';
import Register from '../pages/Auth/Register/Register';
import Dashboard from '../pages/Dashboard/Dashboard';
import Profile from '../pages/Profile/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { userProfile } from '../redux/actions/userAction';
import Loader from '../components/Loader/Loader';
import VerifyToken from '../pages/VerifyToken/VerifyToken';
import DirectMessage from '../pages/DirectMessage/DirectMessage';
import Requests from '../pages/Requests/Requests';
import Channels from '../pages/Channels/Channels';
import CreateChannel from '../pages/CreateChannel/CreateChannel';
import ManageChannel from '../pages/ManageChannel/ManageChannel';
import { Outlet } from 'react-router-dom';
import LikedBy from '../pages/LikedBy/LikedBy';
import LandingPage from '../pages/LandingPage/LandingPage';
import ContactUsPage from '../pages/ContactUsPage/ContactUsPage';

const PrivateRoute = ({ element, ...rest }) => {
    const dispatch = useDispatch();
    const userLoginData = useSelector(state => state.user);

    useEffect(() => {
        dispatch(userProfile());
    }, [dispatch]);

    const isLoggedIn = userLoginData.isLoggedIn;

    if(isLoggedIn === true){
        return <Outlet />;
    }else if(isLoggedIn === false){
        return <Navigate to={'/login'} />
    }

    return <Loader loading={true} />
};


const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/join" element={<Register />} />
                <Route exact path="/verify/:token" element={<VerifyToken />} />
                <Route exact path='/' element={<LandingPage />} />
                <Route exact path='/contact-us' element={<ContactUsPage />} />
                <Route element={<PrivateRoute />}>
                    <Route element={<DefaultLayout />}>
                        <Route exact path='/dashboard' element={<Dashboard />} />
                        <Route exact path='/profile' element={<Profile />} />
                        <Route exact path='/requests' element={<Requests />} />
                        <Route exact path='/chats/*' element={<DirectMessage />} />
                        <Route exact path='/groups/create' element={<CreateChannel />} />
                        <Route exact path='/groups/:channel_id/manage' element={<ManageChannel />} />
                        <Route exact path='/groups/*' element={<Channels />} />
                        <Route exact path='/liked_by' element={<LikedBy />} />
                    </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
};
  

export default AppRouter;
  