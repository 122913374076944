import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { createChannel } from '../../redux/actions/channelAction';
import { useNavigate } from 'react-router-dom';

const CreateChannel = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
	const channelCreate = useSelector(state => state.channel);

    const [name, setName] = useState("");

    const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(createChannel({
            name: name,
        }));
	};

    useEffect(() => {
        if(channelCreate?.createdChannel?._id){
            navigate(`/groups/${channelCreate?.createdChannel?._id}/manage`);
        }

        //eslint-disable-next-line
    },[channelCreate.createdChannel])

    return (
        <div>
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="text-title-md2 font-semibold text-black dark:text-white">Create Group</h2>
            </div>
            <div className="grid grid-cols-1 gap-9">
                <div className="flex flex-col gap-9">
                    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col md:flex-row justify-center md:items-center gap-3 md:gap-5.5 p-6.5">
                                <label className="block text-black dark:text-white">Name</label>
                                <div className='w-full'>
                                    <input 
                                        type="text" 
                                        placeholder="Enter Group Name" 
                                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" 
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {
                                        channelCreate?.errors?.name && <p className='text-sm text-danger'>{ channelCreate?.errors?.name }</p>
                                    }
                                </div>
                                <button type='submit' className="flex justify-center rounded bg-primary py-3 px-8 font-medium text-gray">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Loader loading={ channelCreate.loading } />
        </div>
    )
}

export default CreateChannel