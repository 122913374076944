import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import EmojiPicker from '../EmojiPicker/EmojiPicker';
import HamburgerIcon from '../../assets/icons/HamburgerIcon';
import { GrAttachment } from "react-icons/gr";

const DirectMessageComponent = ({ drawerOpen, setDrawerOpen, toggleDrawer}) => {
	const friends = useSelector(state => state.request);
	const user = useSelector(state => state.user.user);
    const [receiver, setReceiver] = useState();
    const [message, setMessage] = useState('');
    const fileInputRef = useRef(null);
    const [receivedMessages, setReceivedMessages] = useState([]);
    const [socket, setSocket] = useState(null);
    const token = localStorage.getItem('access_token');
    const chatContainerRef = useRef(null);
    const messageInputRef = useRef(null);

    const { direct_message_id } = useParams();

    useEffect(() => {
        const receiver = friends.friends.find((fri) => fri.direct_room_id === direct_message_id);
        if(receiver){
            setReceiver(receiver);
        }
    },[friends.friends, direct_message_id]);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL);
        setSocket(newSocket);

        newSocket.on('connection', () => {
            console.log('Connected to server');
        });

        newSocket.emit('joinDirectMessageRoom', direct_message_id, token);

        newSocket.on('prev_direct_message', (prev_direct_message) => {
            setReceivedMessages(prev_direct_message);
        });

        newSocket.on('direct_message', (message) => {
            setReceivedMessages(prevMessages => [...prevMessages, message]);
        });

        return () => {
            newSocket.disconnect();
        };
        //eslint-disable-next-line
    }, [direct_message_id]);

    const sendMessage = (e) => {
      e.preventDefault();
        if (socket && message) {
            const messageData = {
                room: direct_message_id,
                message: message,
                token: token
            };

            socket.emit('direct_message', messageData);
            setMessage('');
        }
    };

    const handleImageUpload = (e) => {
        const selectedFile  = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith('image/')) {
                const reader = new FileReader(selectedFile);
                reader.onload = (event) => {
                    const imageBase64 = event.target.result;

                    const messageData = {
                        room: direct_message_id,
                        message: "",
                        token: token,
                        type: "image",
                        base64: imageBase64,
                        file_name: selectedFile.name,
                    };
                    socket.emit('direct_message', messageData);
                    fileInputRef.current.value = '';
                };
                reader.readAsDataURL(selectedFile);
            } else {
                alert('Please select a valid image file.');
            }
        }
    }

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [receivedMessages]);

    return (
       <>
            <div className="sticky flex items-center justify-between border-b border-stroke px-6 py-4.5 dark:border-strokedark">
                <div className="flex items-center">
                    <div className='mr-4.5'>
                        <button
                            aria-controls="sidebar"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleDrawer();
                            }}
                            className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
                        >
                            <HamburgerIcon sidebarOpen={drawerOpen} setSidebarOpen={setDrawerOpen}/>
                        </button>
                    </div>
                    <div className="mr-4.5 h-13 w-full max-w-13 overflow-hidden rounded-full">
                        <div className="flex items-center justify-center w-13 h-13 rounded-full font-semibold text-xl text-black drop-shadow-2  bg-gray">
                            {
                                receiver?.images?.length > 0 ? ( <img src={receiver?.images[0]} className='rounded-full w-full' alt='receiver'/>) : (<>
                                    {receiver?.name?.split(' ')[0][0].toUpperCase()} { receiver?.name?.split(' ')?.[1]?.[0]?.toUpperCase()}
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <h5 className="font-medium text-black dark:text-white">{ receiver?.name }</h5>
                        {/* <p className="text-sm text-ellipsis overflow-hidden ...">{ receiver?.email }</p> */}
                    </div>
                </div>
            </div>
            <div className="no-scrollbar max-h-full space-y-3.5 overflow-auto px-6 py-7.5 flex-1" ref={chatContainerRef}>
                {
                    receivedMessages.map((message) => {
                        if(message.sender_id._id === user._id){
                            return (
                                <div className="ml-auto max-w-125" key={message._id}>
                                    <div className="mb-2.5 rounded-2xl rounded-br-none bg-primary py-3 px-5">
                                        {
                                            message.type === 'image' && (
                                                <img src={message.image_link} alt='direct message' className='w-4/5' />
                                            )
                                        }
                                        {
                                            message.type === 'message' && <p className="text-white">{ message.message }</p>
                                        }
                                    </div>
                                    <p className="text-right text-xs">{ moment(message.timestamp).format("MMM Do YY, h:mm:ss a") }</p>
                                </div>
                            );
                        }
                        return (
                            <div className="max-w-125" key={message._id}>
                                <p className="mb-2.5 text-sm font-medium"> {message.sender_id.name }</p>
                                <div className="mb-2.5 rounded-2xl rounded-tl-none bg-gray py-3 px-5 dark:bg-boxdark-2">
                                    {
                                        message.type === 'image' && (
                                            <img src={message.image_link} alt='direct message' className='w-4/5' />
                                        )
                                    }
                                    {
                                        message.type === 'message' && <p>{ message.message }</p>
                                    }
                                </div>
                                <p className="text-xs">{ moment(message.timestamp).format("MMM Do YY, h:mm:ss a") }</p>
                            </div>
                        );
                    })
                }
            </div>
            <div className="sticky bottom-0 border-t border-stroke bg-white py-5 px-6 dark:border-strokedark dark:bg-boxdark">
                <form className="flex items-center justify-between space-x-4.5" onSubmit={sendMessage}>
                    <div className="relative w-full">
                        <input 
                            type="text" 
                            placeholder="Type something here" 
                            className="h-13 w-full rounded-md border border-stroke bg-gray pl-5 pr-20 text-black placeholder-body outline-none focus:border-primary dark:border-strokedark dark:bg-boxdark-2 dark:text-white" 
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            ref={messageInputRef}
                        />
                        <div className="absolute right-13 top-1/2 inline-flex -translate-y-1/2 items-center justify-end space-x-4">
                            <EmojiPicker inputRef={messageInputRef} value={message} setValue={setMessage} />
                        </div>
                        <div className="absolute right-5 top-1/2 inline-flex -translate-y-1/2 items-center justify-end space-x-4 text-gray-500">
                            <label htmlFor='attachment' className='cursor-pointer'>
                                <GrAttachment />
                            </label>
                            <input type='file' className='hidden' id='attachment' onChange={handleImageUpload} ref={fileInputRef}/>
                        </div>
                    </div>
                    <button className="flex h-13 w-full max-w-13 items-center justify-center rounded-md bg-primary text-white hover:bg-opacity-90">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 2L11 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                    </button>
                  
                </form>
            </div>
       </>
    )
}

export default DirectMessageComponent