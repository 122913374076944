import { Link } from 'react-router-dom';
// import DropdownNotification from './DropdownNotification';
// import DropdownUser from './DropdownUser';
import HamburgerIcon from '../../assets/icons/HamburgerIcon';
import Logo from '../Logo/Logo';
import UserDropdownMenu from '../UserDropdownMenu/UserDropdownMenu';

const Header = (props) => {
	return (
		<header className="sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
			<div className="flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11">
				<div className="flex items-center gap-2 sm:gap-4 lg:hidden">
					<button
						aria-controls="sidebar"
						onClick={(e) => {
							e.stopPropagation();
							props.setSidebarOpen(!props.sidebarOpen);
						}}
						className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
					>
						<HamburgerIcon {...props}/>
					</button>

					<Link className="block flex-shrink-0 lg:hidden" to="/dashboard">
						<Logo />
					</Link>
				</div>
				<div className="hidden sm:block"></div>
				<div className="flex items-center gap-3 2xsm:gap-7">
					<ul className="flex items-center gap-2 2xsm:gap-4">
					</ul>
					<UserDropdownMenu />
				</div>
			</div>
		</header>
	);
};

export default Header;
