import {
    CHANNEL_LIST_REQUEST,
    CHANNEL_LIST_SUCCESS,
    CHANNEL_LIST_FAILURE,
    CHANNEL_CREATE_REQUEST,
    CHANNEL_CREATE_SUCCESS,
    CHANNEL_CREATE_FAILURE,
    CHANNEL_REQUEST,
    CHANNEL_SUCCESS,
    CHANNEL_FAILURE,
    ACTION_REQUEST,
    ACTION_SUCCESS,
    ACTION_FAILURE,
  } from '../actions/channelAction';
    
  const initialState = {
    loading: false, 
    successMessage: '',
    channels: [],
    createdChannel: {},
    channel: {},
    errors: {},
  };
    
  const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANNEL_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case CHANNEL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                channels: action.payload.channels,
                errors: {},
            };
        case CHANNEL_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: { message: action.payload },
            };
        case CHANNEL_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case CHANNEL_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                createdChannel: action.payload.channel,
                errors: {},
            };
        case CHANNEL_CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: action.payload,
        };
        case CHANNEL_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case CHANNEL_SUCCESS:
            return {
                ...state,
                loading: false,
                channel: action.payload.channel,
                errors: {},
            };
        case CHANNEL_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: { message: action.payload },
            };
        case ACTION_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case ACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                channel: action.payload.channel,
                errors: {},
            };
        case ACTION_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: { message: action.payload },
            };
        default:
            return state;
    }
  };
    
  export default requestReducer;
  