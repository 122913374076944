import {
    SEARCH_USER_REQUEST,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILURE,
    SEND_REQUEST_REQUEST,
    SEND_REQUEST_SUCCESS,
    SEND_REQUEST_FAILURE,
    REQUEST_ACTION_REQUEST,
    REQUEST_ACTION_SUCCESS,
    REQUEST_ACTION_FAILURE,
    FRIEND_LIST_REQUEST,
    FRIEND_LIST_SUCCESS,
    FRIEND_LIST_FAILURE,
    REQUEST_LIST_REQUEST,
    REQUEST_LIST_SUCCESS,
    REQUEST_LIST_FAILURE,
    LIKE_USER_REQUEST,
    LIKE_USER_SUCCESS,
    LIKE_USER_FAILURE,
    LIKE_BY_USER_LIST_REQUEST,
    LIKE_BY_USER_LIST_SUCCESS,
    LIKE_BY_USER_LIST_FAILURE,
  } from '../actions/requestAction';
    
  const initialState = {
    loading: false, 
    successMessage: '',
    searchedUsers: [],
    friends: [],
    requests: [],
    errors: {},
    likedByUsers: [],
  };
    
  const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_USER_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case SEARCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                searchedUsers: action.payload.users,
                errors: {},
            };
        case SEARCH_USER_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: action.payload.errors,
            };
        case SEND_REQUEST_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case SEND_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload.message,
                errors: {},
            };
        case SEND_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: { message: action.payload },
            };
        case REQUEST_ACTION_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case REQUEST_ACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload.message,
                errors: {},
            };
        case REQUEST_ACTION_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: { message: action.payload },
            };
        case FRIEND_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case FRIEND_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                friends: action.payload.users,
                errors: {},
            };
        case FRIEND_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: { message: action.payload },
            };
        case REQUEST_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case REQUEST_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                requests: action.payload.users,
                errors: {},
            };
        case REQUEST_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: { message: action.payload },
            };
        case LIKE_USER_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case LIKE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload.message,
                errors: {},
            };
        case LIKE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: action.payload.errors,
            };
        case LIKE_BY_USER_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errors: {},
            };
        case LIKE_BY_USER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                likedByUsers: action.payload.users,
                errors: {},
            };
        case LIKE_BY_USER_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                successMessage: '',
                errors: action.payload.errors,
            };
        default:
            return state;
    }
  };
    
  export default requestReducer;
  
  