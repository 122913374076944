import React from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import NotFoundImage from './../../assets/images/notfound.jpg';	

const NotFound = () => {
	return (
		<div className="flex justify-center items-center h-[100vh] p-10">
			<div className="rounded-sm border border-stroke bg-white px-5 py-10 shadow-default dark:border-strokedark dark:bg-boxdark sm:py-20">
				<div className="mx-auto max-w-[410px]">
					<img src={NotFoundImage} alt="illustration" className='w-2/3 mx-auto' />
					<div className="mt-7.5 text-center">
						<h2 className="mb-3 text-2xl font-bold text-black dark:text-white">Sorry, the page can’t be found</h2>
						<p className="font-medium">The page you were looking for appears to have been moved, deleted or does not exist.</p>
						<Link className="mt-7.5 inline-flex items-center gap-2 rounded-md bg-primary py-3 px-6 font-medium text-white hover:bg-opacity-90" to="/dashboard">
							<BsArrowLeft />
							<span>Back to Dashboard</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotFound