import React from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { verifyToken } from '../../redux/actions/userAction';
import Logo from '../../components/Logo/Logo';

const VerifyToken = () => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const { token } = useParams();

	const handleVerifyEmail = (e) => {
		e.preventDefault();
		if(token){
			dispatch(verifyToken(token));
		}
	}

	return (
		<div className="flex justify-center items-center h-[100vh] p-10">
			<div className="rounded-sm border border-stroke bg-white px-5 py-10 shadow-default dark:border-strokedark dark:bg-boxdark sm:py-10">
				<div className="mx-auto w-[410px]">
					<div className="mt-0 text-center">
						<Logo logoColor="text-black" />

						<div className='mt-10'>
							{
								!user?.successMessage && !user?.errors?.message  && 
								<button className="mt-7.5 inline-flex items-center gap-2 rounded-md bg-primary py-3 px-6 font-medium text-white hover:bg-opacity-90" onClick={handleVerifyEmail}>
									Verify your Email
								</button>
							}

							{
								user?.successMessage && <p className='text-success text-xl'> { user?.successMessage }</p>
							}

							{
								user?.errors?.message && <p className='text-danger text-xl'> { user?.errors?.message }</p>
							}

							{
								user?.successMessage &&
								<Link className="mt-7.5 inline-flex items-center gap-2 rounded-md bg-primary py-3 px-6 font-medium text-white hover:bg-opacity-90" to="/login">
									<BsArrowLeft />
									<span>Login</span>
								</Link>
							}
						</div>
					</div>
				</div>
			</div>
			<Loader loading={user?.loading} />
		</div>
	)
}

export default VerifyToken