import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userUpdate } from '../../redux/actions/userAction';
import { BiUser } from 'react-icons/bi';
import { BsCardImage } from 'react-icons/bs';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import cities from '../../assets/data/cities.json'
import { toast } from 'react-toastify';
import Select from 'react-select';

const Dashboard = () => {
    const user = useSelector(state => state.user.user);
    const update = useSelector(state => state.user);
	const [ error, setError ] = useState("");
    const dispatch = useDispatch();
    const filesRef = useRef();

    const [userData, setUserData] = useState({
		name: user.name,
		dob: user.dob ? new Date(user.dob) : "",
		gender: user.gender,
		bio: user.bio || "",
		city: user.city || "",
        state: user.state || "",
        images: [],
        active_image_urls: user.images || [],
	});

    // useEffect(() => {
    //     getLocation();
    // }, []);

    // const getLocation = () => {
	// 	if ("geolocation" in navigator) {
	// 		navigator.geolocation.getCurrentPosition(
	// 			(position) => {
	// 				const { latitude, longitude } = position.coords;
	// 				setUserData((userData) => ({ ...userData, lat: latitude, long: longitude }));
	// 			},
	// 			(error) => {
	// 				setError(error.message);
	// 			}
	// 		);
	// 	} else {
	// 	 	setError("Geolocation is not supported in your browser.");
	// 	}
	// }

    const handleSubmit = (e) => {
        setError("");
		e.preventDefault();
		// if(userData?.lat && userData?.long){
			dispatch(userUpdate(userData, onUserUpdate));
		// }else {
		// 	setError("Allow location to continue");
		// 	getLocation();
	   	// }
	};

    const onUserUpdate = (user) => {
        filesRef.current.value = "";
        setUserData((data) => (
            {
                ...data,
                active_image_urls: user.images || [],
            }
        ))
        toast.success("Profile updated Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    }

    const handleImagesUpload = (e) => {
        e.preventDefault();
        const selectedFiles = Array.from(e.target.files);

        const allImages = selectedFiles.every((image) =>
            image.type.startsWith("image/")
        );

        if(!allImages){
            alert("Please select only image files.");
            e.target.value = "";
            return;
        }

        if(selectedFiles){
            setUserData((data) => (
                {
                    ...data,
                    images: selectedFiles,
                }
            ))
        }
    }

    const deleteImage = (index) => {
        const data = {...userData};

        data.active_image_urls = data.active_image_urls.filter((url, i) => {
            if(i === index){
                return false;
            }
            return true;
        });

        setUserData(data);
    }

    return (
        <div>
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="text-title-md2 font-semibold text-black dark:text-white">Profile</h2>
            </div>
            <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="px-4 pb-6 text-center lg:pb-8 xl:pb-11.5">
                    <div className="relative flex justify-center mt-8">
                        <div className="flex items-center justify-center w-30 h-30 bg-blue-500 rounded-full font-semibold text-6xl text-black drop-shadow-2 shadow bg-gray">
                            {
                                user?.images?.length > 0 ? ( <img src={user?.images[0]} className='rounded-full w-full' alt='user'/>) : (
                                    <>
                                    {user?.name?.split(' ')[0][0].toUpperCase()} { user?.name?.split(' ')?.[1]?.[0]?.toUpperCase()}
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="mt-4">
                        <h3 className="mb-1.5 text-2xl font-medium text-black dark:text-white">
                            { user?.name }
                        </h3>
                        <p className="font-medium"> { user?.email } </p>
                        {/* <div
                        className="dark:bg-[#37404F] mx-auto mt-4.5 mb-5.5 grid max-w-94 grid-cols-3 rounded-md border border-stroke py-2.5 shadow-1 dark:border-strokedark">
                        <div
                            className="flex flex-col items-center justify-center gap-1 border-r border-stroke px-4 dark:border-strokedark xsm:flex-row">
                            <span className="font-semibold text-black dark:text-white">259</span>
                            <span className="text-sm">Posts</span>
                        </div>
                        <div
                            className="flex flex-col items-center justify-center gap-1 border-r border-stroke px-4 dark:border-strokedark xsm:flex-row">
                            <span className="font-semibold text-black dark:text-white">129K</span>
                            <span className="text-sm">Followers</span>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-1 px-4 xsm:flex-row">
                            <span className="font-semibold text-black dark:text-white">2K</span>
                            <span className="text-sm">Following</span>
                        </div>
                        </div> */}                        
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center py-10'>
                <div className="w-full md:w-2/3 p-6 sm:p-0 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark h-fit">
                    <div className="w-full border-stroke dark:border-strokedark">
                        <div className="w-full p-4 sm:p-12.5 xl:p-12">
                            {error && (
                                <p className="text-danger text-xl text-center">{error}</p>
                            )}
                            {update.successMessage && (
                                <p className="text-success text-xl text-center">{update.successMessage}</p>
                            )}
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                                        Name
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Enter your name"
                                            className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                            value={userData.name}
                                            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                        />

                                        <span className="absolute right-5 top-5 text-xl">
                                            <BiUser />
                                        </span>
                                    </div>
                                    {
                                        update?.errors?.name && <p className='text-sm text-danger'>{ update?.errors?.name }</p>
                                    }
                                </div>
                                <div className="mb-6">
                                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                                        Date of Birth
                                    </label>
                                    <div className="relative">
                                        <DatePicker
                                            format="yyyy-MM-dd"
                                            value={userData.dob}
                                            onChange={(date) => setUserData({ ...userData, dob: date })}
                                            className='w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-3 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary'
                                            maxDate={new Date()}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>
                                    {
                                        update?.errors?.dob && <p className='text-sm text-danger'>{ update?.errors?.dob }</p>
                                    }
                                </div>
                                <div className="mb-6">
                                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                                        Gender
                                    </label>
                                    <div className="flex">
                                        <div className='w-1/2 flex gap-3 items-center'>
                                            <label className="block font-medium text-black dark:text-white" htmlFor='male'>
                                                Male
                                            </label>
                                            <input type='radio' id='male' name='gender' onChange={(e) => {setUserData({ ...userData, gender: 'male' })}} checked={userData.gender === 'male'}/>
                                        </div>
                                        <div className='w-1/2 flex gap-3 items-center'>
                                            <label className="block font-medium text-black dark:text-white" htmlFor='female'>
                                                Female
                                            </label>
                                            <input type='radio' id='female' name='gender' onChange={(e) => {setUserData({ ...userData, gender: 'female' })}} checked={userData.gender === 'female'}/>
                                        </div>
                                    </div>
                                    {
                                        update?.errors?.gender && <p className='text-sm text-danger'>{ update?.errors?.gender }</p>
                                    }
                                </div>
                                <div className="mb-6">
                                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                                        Bio
                                    </label>
                                    <div className="relative">
                                        <textarea className='w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary' value={userData.bio} onChange={(e) => {
                                            setUserData({ ...userData, bio: e.target.value})
                                        }}>

                                        </textarea>
                                    </div>
                                    {
                                        update?.errors?.bio && <p className='text-sm text-danger'>{ update?.errors?.bio }</p>
                                    }
                                </div>
                                <div className='mb-6'>
                                    <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                                        Select State
                                    </label>
                                    <div className="relative z-20 bg-white dark:bg-form-input">
                                        <Select
                                            classNames="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="state"
                                            options={
                                                Object.keys(cities).map((state) => ({
                                                    value: state,
                                                    label: state
                                                }))
                                            }
                                            value={{ 
                                                value: userData.state,
                                                label: userData.state
                                            }}
                                            onChange={(e) => setUserData({ ...userData, state: e.value, city: "" })}
                                        />
                                    </div>
                                </div>
                                <div className='mb-6'>
                                    <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                                        Select City
                                    </label>
                                    <div className="relative bg-white dark:bg-form-input">
                                        <Select
                                            classNames="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="city"
                                            options={
                                                userData.state ? cities[userData.state].map((city, index) => ({ 
                                                    value: city,
                                                    label: city
                                                })) : []
                                            }
                                            value={{ 
                                                value: userData.city,
                                                label: userData.city
                                            }}
                                            onChange={(e) => setUserData({ ...userData, city: e.value })}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                                        Images
                                    </label>
                                    <div className="relative">
                                        <input
                                            ref={filesRef}
                                            type="file"
                                            placeholder="Enter your name"
                                            className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                            onChange={handleImagesUpload}
                                            multiple
                                        />

                                        <span className="absolute right-5 top-5 text-xl">
                                            <BsCardImage />
                                        </span>
                                    </div>
                                    {
                                        update?.errors?.name && <p className='text-sm text-danger'>{ update?.errors?.name }</p>
                                    }
                                </div>
                                <div className="mb-6">
                                    <div className='grid grid-cols-4 gap-4 py-5'>
                                        {
                                            userData.active_image_urls.map((image, index) => (
                                                <div key={index} className='text-center border pb-4'>
                                                    <img src={image} alt='user' className='w-full mb-4' />
                                                    <button type='button' onClick={(e) => { e.preventDefault(); deleteImage(index) }} className='text-xs outline-none border py-1 px-4 '>
                                                        Delete
                                                    </button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <input
                                        type="submit"
                                        value="Update"
                                        className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard