import React, { useEffect } from 'react'
import { getRequests } from '../../redux/actions/requestAction';
import { useDispatch, useSelector } from 'react-redux';
import UserCard from '../../components/UserCard/UserCard';
import Loader from '../../components/Loader/Loader';

const Requests = () => {
    const dispatch = useDispatch();
    const userRequest = useSelector(state => state.request);

    const handleSuccess = () => {
        dispatch(getRequests());
    }

    useEffect(() => {
        dispatch(getRequests());
        //eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="text-title-md2 font-semibold text-black dark:text-white">Requests</h2>
            </div>
             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-9">
                {
                    userRequest?.requests?.map((user) => {
                        return (
                            <UserCard user={user} key={user._id} successAction={handleSuccess} />
                        );
                    })
                }
                {
                    userRequest.requests.length === 0 && <h3 className='font-semibold text-black dark:text-white'>No more Requests.</h3>
                }
            </div>
            <Loader loading={ userRequest.loading } />
        </div>
    )
}

export default Requests