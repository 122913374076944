import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from 'redux-thunk';
import userReducer from "./redux/reducers/userReducer";
import AppRouter from "./routes/AppRouter";
import { Provider } from "react-redux";
import requestReducer from "./redux/reducers/requestReducer";
import channelReducer from "./redux/reducers/channelReducer";


const rootReducer = combineReducers({
    user: userReducer,
    request: requestReducer,
    channel: channelReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const App = () => {
	return (
        <Provider store={store}>
            <div className="App">
                <AppRouter />
            </div>
        </Provider>
	);
}

export default App;
