import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader/Loader';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LeftArrowIcon from '../../assets/icons/LeftArrowIcon';
import HamburgerIcon from '../../assets/icons/HamburgerIcon';
import { getChannels } from '../../redux/actions/channelAction';
import ChannelMessageComponent from '../../components/ChannelMessageComponent/ChannelMessageComponent';

const Channels = () => {
    const dispatch = useDispatch();
    const location = useLocation();

	const channels = useSelector(state => state.channel);
    const [drawerOpen, setDrawerOpen] = useState(true);

	const notification_count = useSelector(state => state.user.notification_count);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    

    useEffect(() => {
        dispatch(getChannels());

        //eslint-disable-next-line
    },[]);

    return (
        <div>
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="text-title-md2 font-semibold text-black dark:text-white">Groups</h2>
                <Link to={'/groups/create'} className="flex justify-center rounded bg-primary py-2 px-8 font-medium text-gray">
                    Create Group
                </Link>
            </div>
            <div className="h-[calc(100vh-186px)] overflow-hidden sm:h-[calc(100vh-174px)]">
                <div className="h-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:flex relative">
                    <div className={`flex h-full flex-col xl:flex xl:w-1/4 bg-white shadow-default lg:static lg:translate-x-0 lg:border-r-0 ${drawerOpen ? 'translate-x-0 border-r border-stroke' : '-translate-x-full'} absolute left-0 top-0 z-999 duration-300 ease-linear`}>
                        <div className="sticky border-b border-stroke px-6 py-7.5 dark:border-strokedark flex justify-between gap-5">
                            <h3 className="text-lg font-medium text-black dark:text-white 2xl:text-xl">
                                Active Groups
                            </h3>
                            <button
                                onClick={toggleDrawer}
                                aria-controls="sidebar"
                                aria-expanded={drawerOpen}
                                className="block lg:hidden"
                            >
                                <LeftArrowIcon />
			                </button>
                        </div>
                        <div className="flex max-h-full flex-col overflow-auto p-5">
                            <div className="no-scrollbar max-h-full space-y-2.5 overflow-auto">
                                {
                                    channels?.channels.map((channel) => {
                                        return (
                                            <Link to={`/groups/${channel._id}`} key={channel._id} onClick={() => {
                                                setDrawerOpen(false);
                                            }}>
                                                <div className={`flex cursor-pointer items-center rounded py-2 px-4 hover:bg-gray-2 dark:hover:bg-strokedark`}>
                                                    <div className="relative mr-3.5 h-11 w-full max-w-11 rounded-full">
                                                        <div className="flex items-center justify-center w-12 h-12 rounded-full font-semibold text-xl text-black drop-shadow-2  bg-gray">
                                                            { channel?.name?.split(' ')[0][0].toUpperCase() }{ channel?.name?.split(' ')?.[1]?.[0]?.toUpperCase() }
                                                        </div>
                                                    </div>
                                                    <div className="w-full">
                                                        <h5 className="text-sm font-medium text-black dark:text-white flex gap-5">
                                                            { channel.name }
                                                            {
                                                                notification_count?.channel_messages?.[channel._id] > 0 && <span className="rounded bg-primary py-1 px-2.5 text-xs font-medium text-white">{ notification_count?.channel_messages?.[channel._id] }</span>
                                                            }
                                                        </h5>
                                                        <p className="text-sm"> { channel.admin_id.name }</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                               
                            </div>
                        </div>
                    </div>
                    <div className="flex h-full flex-col border-l border-stroke dark:border-strokedark xl:w-3/4">
                        {
                            location.pathname === "/groups/" && 
                            <div className='sticky flex items-center justify-between border-b border-stroke px-6 py-6.5 dark:border-strokedark'>
                                <button
                                    aria-controls="sidebar"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleDrawer();
                                    }}
                                    className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
                                >
                                    <HamburgerIcon sidebarOpen={drawerOpen} setSidebarOpen={setDrawerOpen}/>
                                </button>
                            </div>
                        }
                        <Routes>
                            <Route path="/:channel_id" element={<ChannelMessageComponent drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} toggleDrawer={toggleDrawer} />} />
                        </Routes>
                    </div>
                </div>
            </div>
            <Loader loading={ channels.loading } />
        </div>
    )
}

export default Channels;