// userActions.js
import axios from 'axios';

export const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';

export const SEND_REQUEST_REQUEST = 'SEND_REQUEST_REQUEST';
export const SEND_REQUEST_SUCCESS = 'SEND_REQUEST_SUCCESS';
export const SEND_REQUEST_FAILURE = 'SEND_REQUEST_FAILURE';

export const REQUEST_ACTION_REQUEST = 'REQUEST_ACTION_REQUEST';
export const REQUEST_ACTION_SUCCESS = 'REQUEST_ACTION_SUCCESS';
export const REQUEST_ACTION_FAILURE = 'REQUEST_ACTION_FAILURE';

export const FRIEND_LIST_REQUEST = 'FRIEND_LIST_REQUEST';
export const FRIEND_LIST_SUCCESS = 'FRIEND_LIST_SUCCESS';
export const FRIEND_LIST_FAILURE = 'FRIEND_LIST_FAILURE';

export const REQUEST_LIST_REQUEST = 'REQUEST_LIST_REQUEST';
export const REQUEST_LIST_SUCCESS = 'REQUEST_LIST_SUCCESS';
export const REQUEST_LIST_FAILURE = 'REQUEST_LIST_FAILURE';

export const LIKE_USER_REQUEST = "LIKE_USER_REQUEST";
export const LIKE_USER_SUCCESS = "LIKE_USER_SUCCESS";
export const LIKE_USER_FAILURE = "LIKE_USER_FAILURE";

export const LIKE_BY_USER_LIST_REQUEST = "LIKE_BY_USER_LIST_REQUEST";
export const LIKE_BY_USER_LIST_SUCCESS = "LIKE_BY_USER_LIST_SUCCESS";
export const LIKE_BY_USER_LIST_FAILURE = "LIKE_BY_USER_LIST_FAILURE";

export const searchUsers = (searchData) => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: SEARCH_USER_REQUEST });
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/search`, searchData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: SEARCH_USER_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
             dispatch({ type: SEARCH_USER_FAILURE, payload: response.data.errors });
            }
        } catch (error) {
            dispatch({ type: SEARCH_USER_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};

export const sendRequest = (user_id, successAction) => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: SEND_REQUEST_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${user_id}/send_request`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: SEND_REQUEST_SUCCESS, payload: response.data });
                if(successAction){
                    successAction();
                }
            } else if (response.data.errors) {
                dispatch({ type: SEND_REQUEST_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            dispatch({ type: SEND_REQUEST_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};

export const requestAction = (user_id, status , successAction) => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: REQUEST_ACTION_REQUEST });
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/${user_id}/request_action`, {
                status: status
            },{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: REQUEST_ACTION_SUCCESS, payload: response.data });
                if(successAction){
                    successAction();
                }
            } else if (response.data.errors) {
                dispatch({ type: REQUEST_ACTION_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: REQUEST_ACTION_FAILURE, payload: error.response.message });
            } else {
                dispatch({ type: REQUEST_ACTION_FAILURE, payload: 'An error occurred during searching users.' });
            }
            
        }
    };
};

export const getFriends = () => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: FRIEND_LIST_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/friends`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: FRIEND_LIST_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: FRIEND_LIST_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            dispatch({ type: FRIEND_LIST_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};

export const getRequests = () => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: REQUEST_LIST_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/requests`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: REQUEST_LIST_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: REQUEST_LIST_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            dispatch({ type: REQUEST_LIST_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};


export const likeUser = (user_id, successCallback) => {
    const token = localStorage.getItem('access_token');
    
    return async (dispatch) => {
        dispatch({ type: LIKE_USER_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${user_id}/like`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.message) {
                dispatch({ type: LIKE_USER_SUCCESS, payload: response.data });
                if(successCallback){
                    successCallback();
                }
            } else if (response.data.errors) {
             dispatch({ type: LIKE_USER_FAILURE, payload: response.data.errors });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: LIKE_USER_FAILURE, payload: error.response.data });
            } else {
                dispatch({ type: LIKE_USER_FAILURE, payload: 'An error occurred during registration.' });
            }
        }
    };
};

export const getLikedByUsers = () => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: LIKE_BY_USER_LIST_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/likedBy`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: LIKE_BY_USER_LIST_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
             dispatch({ type: LIKE_BY_USER_LIST_FAILURE, payload: response.data.errors });
            }
        } catch (error) {
            dispatch({ type: LIKE_BY_USER_LIST_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};
