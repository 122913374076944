import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UserCard from '../../components/UserCard/UserCard';
import Loader from '../../components/Loader/Loader';
import { getLikedByUsers } from '../../redux/actions/requestAction';

const LikedBy = () => {
    const dispatch = useDispatch();
	const user = useSelector(state => state.request);

    useEffect(() => {
        dispatch(getLikedByUsers());
        //eslint-disable-next-line
    },[]);

    const handleSuccess = () => {
        dispatch(getLikedByUsers());
    }
    return (
        <div>
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="text-title-md2 font-semibold text-black dark:text-white">Liked By</h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-9 py-4 md:py-6 2xl:py-10">
                {
                    user?.likedByUsers?.map((user) => {
                        return (
                            <UserCard user={user} key={user._id} successAction={handleSuccess} />
                        );
                    })
                }
                {
                    user?.likedByUsers.length === 0 ? <p>You have not receive any likes yet.</p> : ''
                }
            </div>
            <Loader loading={ user.loading } />
        </div>
    )
}

export default LikedBy