import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { addUser, getChannel, leaveChannel, removeUser } from '../../redux/actions/channelAction';
import { useParams } from 'react-router-dom';
import { getFriends } from '../../redux/actions/requestAction';

const ManageChannel = () => {
    const dispatch = useDispatch();
    const { channel_id } = useParams();

	const channel = useSelector(state => state.channel);
	const friends = useSelector(state => state.request.friends);
	const authUser = useSelector(state => state.user.user);

    const [channelMemberIds, setChannelMemberIds] = useState([]);

    const handleAddUser = (e, user_id) => {
		e.preventDefault();
		dispatch(addUser(channel_id, user_id));
	};

    const handleRemoveUser = (e, user_id) => {
		e.preventDefault();
		dispatch(removeUser(channel_id, user_id));
	};

    const handleLeaveChannel = (e) => {
		e.preventDefault();
		dispatch(leaveChannel(channel_id));
	};

    useEffect(() => {
        dispatch(getChannel(channel_id));
        dispatch(getFriends());
        //eslint-disable-next-line
    },[]);

    useEffect(() => {
        if(channel?.channel?.members){
            setChannelMemberIds(channel.channel.members.map(m => m._id));
        }        
    },[channel.channel]);

    return (
        <div>
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="text-title-md2 font-semibold text-black dark:text-white">Create Group</h2>
            </div>
            <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="px-4 pb-6 text-center lg:pb-4">
                    <div className="relative flex justify-center mt-8">
                        <div className="flex items-center justify-center w-30 h-30 bg-blue-500 rounded-full font-semibold text-6xl text-black drop-shadow-2 shadow bg-gray">
                            { channel?.channel?.name?.split(' ')[0][0].toUpperCase() }{ channel?.channel?.name?.split(' ')?.[1]?.[0]?.toUpperCase() }
                        </div>
                    </div>
                    <div className="mt-4">
                        <h3 className="mb-1.5 text-2xl font-medium text-black dark:text-white">
                            { channel?.channel?.name }
                        </h3>
                        <p className="font-medium"> { channel?.channel?.admin_id?.name } </p>
                        <div
                            className="dark:bg-[#37404F] mx-auto mt-4.5 mb-5.5 grid max-w-94 grid-cols-1 rounded-md border border-stroke py-2.5 shadow-1 dark:border-strokedark">
                            <div
                                className="flex flex-col items-center justify-center gap-1 border-r border-stroke px-4 dark:border-strokedark xsm:flex-row">
                                <span className="font-semibold text-black dark:text-white">{channel?.channel?.members?.length + 1}</span>
                                <span className="text-sm">Members</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-8'>
                <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="text-title-md font-semibold text-black dark:text-white">Members</h2>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-9">
                    <div className="">
                        <div className="p-6.5 flex gap-3 bg-white rounded-sm border border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                            <div className="relative z-30 w-1/3 rounded-full bg-white/20 p-1 backdrop-blur">
                                <div className="relative drop-shadow-2 flex items-center">
                                    <div className="flex items-center justify-center w-20 h-20 rounded-full font-semibold text-4xl text-black drop-shadow-2  bg-gray">
                                        {
                                            channel?.channel?.admin_id?.images?.length > 0 ? ( <img src={channel?.channel?.admin_id?.images[0]} className='rounded-full w-full' alt='user'/>) : ( <>
                                            {channel?.channel?.admin_id?.name?.split(' ')[0][0].toUpperCase()} { channel?.channel?.admin_id?.name?.split(' ')?.[1]?.[0]?.toUpperCase()}
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col justify-center w-2/3'>
                                <h3 className="mb-1.5 text-2xl font-medium text-black dark:text-white">
                                    { channel?.channel?.admin_id?.name }
                                </h3>
                                {/* <p className="font-medium text-ellipsis overflow-hidden ..."> { channel?.channel?.admin_id?.email } </p> */}
                            </div>
                        </div>
                    </div>
                    {
                        channel?.channel?.members?.map((user) => {
                            return (
                                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark" key={user._id}>
                                    <div className="p-6.5 flex gap-3">
                                        <div className="relative z-30 w-1/3 rounded-full bg-white/20 p-1 backdrop-blur">
                                            <div className="relative drop-shadow-2 flex items-center">
                                                <div className="flex items-center justify-center w-20 h-20 rounded-full font-semibold text-4xl text-black drop-shadow-2  bg-gray">
                                                    {
                                                        user?.images?.length > 0 ? ( <img src={user?.images[0]} className='rounded-full w-full' alt='user'/>) : ( <>
                                                        {user?.name?.split(' ')[0][0].toUpperCase()} { user?.name?.split(' ')?.[1]?.[0]?.toUpperCase()}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col justify-center w-2/3'>
                                            <h3 className="mb-1.5 text-2xl  font-medium text-black dark:text-white">
                                                { user.name }
                                            </h3>
                                            {/* <p className="font-medium text-ellipsis overflow-hidden ..."> { user.email } </p> */}
                                        </div>
                                    </div>
                                    {
                                        (authUser?._id === channel?.channel?.admin_id?._id || user?._id === authUser?._id) &&
                                        <div className="px-6.5 pb-6.5 flex justify-center">
                                            <div className="flex flex-wrap items-center">
                                                {
                                                    authUser?._id === channel?.channel?.admin_id?._id && 
                                                    <button className="inline-flex items-center gap-2.5 border border-stroke py-1 px-2 font-medium text-black hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:text-white dark:hover:border-primary sm:py-2 sm:px-6 bg-danger text-white" onClick={(e) => {handleRemoveUser(e, user?._id)}}>
                                                        Remove
                                                    </button>
                                                }

                                                {
                                                    user?._id === authUser?._id  && 
                                                    <button className="inline-flex items-center gap-2.5 border border-stroke py-1 px-2 font-medium text-black hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:text-white dark:hover:border-primary sm:py-2 sm:px-6 bg-danger text-white" onClick={handleLeaveChannel}>
                                                        Leave
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            {
                authUser?._id === channel?.channel?.admin_id?._id &&
                <div className='py-8'>
                    <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                        <h2 className="text-title-md font-semibold text-black dark:text-white">Add Members</h2>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-9">
                        {
                            friends?.filter((user) => {
                                return !channelMemberIds.includes(user?._id);
                            })?.map((user) => {
                                return (
                                    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark" key={user._id}>
                                        <div className="p-6.5 flex gap-3">
                                            <div className="relative z-30 w-1/3 rounded-full bg-white/20 p-1 backdrop-blur">
                                                <div className="relative drop-shadow-2 flex items-center">
                                                    <div className="flex items-center justify-center w-20 h-20 rounded-full font-semibold text-4xl text-black drop-shadow-2  bg-gray">
                                                        {
                                                            user?.images?.length > 0 ? ( <img src={user?.images[0]} className='rounded-full w-full' alt='user'/>) : ( <>
                                                            {user?.name?.split(' ')[0][0].toUpperCase()} { user?.name?.split(' ')?.[1]?.[0]?.toUpperCase()}
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex flex-col justify-center w-2/3'>
                                                <h3 className="mb-1.5 text-2xl font-medium text-black dark:text-white">
                                                    { user.name }
                                                </h3>
                                                {/* <p className="font-medium text-ellipsis overflow-hidden ..."> { user.email } </p> */}
                                            </div>
                                        </div>
                                        
                                        <div className="px-6.5 pb-6.5 flex justify-center">
                                            <div className="flex flex-wrap items-center">
                                                <button className="inline-flex items-center gap-2.5 border border-stroke py-1 px-2 font-medium text-black hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:text-white dark:hover:border-primary sm:py-2 sm:px-6 bg-success text-white" onClick={(e) => {handleAddUser(e, user?._id)}}>
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            }
            <Loader loading={ channel.loading } />
        </div>
    )
}

export default ManageChannel