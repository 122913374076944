import React, { useEffect } from 'react'

const ContactUsPage = () => {
    useEffect(() => {
        const iframe = document.getElementById('landing_page');
    
        const handleMessage = (event) => {
            if (event.source === iframe.contentWindow) {
                window.location.href = event.data;
            }
        };
    
        window.addEventListener('message', handleMessage);
    
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    return (
        <div>
            <iframe
                id="landing_page"
                src="/landing_page/contact.html" 
                title="Landing"
                width="100%"
                style={{ height: '100vh' }}
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </div>
    )
}

export default ContactUsPage