import React from 'react'

const Logo = ({ logoColor = "text-white" }) => {
	return (
		<h1 className={`text-2xl sm:text-title-xl2 ${logoColor}`}>
			Fotiom
		</h1>
	);
}

export default Logo