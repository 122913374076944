import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnvelopeIcon from '../../../assets/icons/EnvelopeIcon.js';
import LockIcon from '../../../assets/icons/LockIcon.js';
import { BiUser } from 'react-icons/bi';
import { registerUser } from '../../../redux/actions/userAction.js';
import Loader from '../../../components/Loader/Loader.jsx';
import { Link } from 'react-router-dom';
import cities from '../../../assets/data/cities.json'
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Select from 'react-select';

const Register = () => {
	const dispatch = useDispatch();
	const userRegistration = useSelector(state => state.user);
	// const [ error, setError ] = useState("");

	const [userData, setUserData] = useState({
		name: '',
		email: '',
		password: '',
		confirm_password: '',
		dob: '',
		gender: '',
		bio: '',
		city: '',
		state: '',
	});

	// useEffect(() => {
	// 	getLocation();
	// }, []);

	// const getLocation = () => {
	// 	if ("geolocation" in navigator) {
	// 		navigator.geolocation.getCurrentPosition(
	// 			(position) => {
	// 				const { latitude, longitude } = position.coords;
	// 				setUserData((userData) => ({ ...userData, lat: latitude, long: longitude }));
	// 			},
	// 			(error) => {
	// 				setError(error.message);
	// 			}
	// 		);
	// 	} else {
	// 	 	setError("Geolocation is not supported in your browser.");
	// 	}
	// }
	
	const { name, email, password, confirm_password } = userData;

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(registerUser(userData));
	};

	useEffect(() => {
		setUserData({
			name: '',
			email: '',
			password: '',
			confirm_password: '',
			dob: '',
			gender: '',
			bio: '',
			city: '',
			state: '',
		});
	},[userRegistration.successMessage]);
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className='flex justify-center items-center p-4 sm:p-10 min-h-screen bg-auth bg-cover bg-center'>
			<div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3 p-6 sm:p-0 rounded-sm border border-stroke bg-white bg-opacity-60 shadow-default dark:border-strokedark dark:bg-boxdark h-fit">
				<div className="w-full border-stroke dark:border-strokedark">
					<div className="w-full p-4 sm:p-12.5 xl:p-17.5">
						<span className="mb-1.5 block font-medium"></span>
						<h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
							Join Fotiom
						</h2>
						
						{/* {error && (
							<p className="text-danger text-xl text-center">{error}</p>
						)} */}
						<form onSubmit={handleSubmit}>
							<div className="mb-4">
								<label className="mb-2.5 block font-medium text-black dark:text-white">
									Name
								</label>
								<div className="relative">
									<input
										type="text"
										placeholder="Enter your name"
										className="w-full rounded-lg border border-stroke py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
										value={name}
  										onChange={(e) => setUserData({ ...userData, name: e.target.value })}
									/>

									<span className="absolute right-5 top-5 text-xl">
										<BiUser />
									</span>
								</div>
								{
									userRegistration?.errors?.name && <p className='text-sm text-danger'>{ userRegistration?.errors?.name }</p>
								}
							</div>
							<div className="mb-4">
								<label className="mb-2.5 block font-medium text-black dark:text-white">
									Email
								</label>
								<div className="relative">
									<input
										type="email"
										placeholder="Enter your email"
										className="w-full rounded-lg border border-stroke py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
										value={email}
  										onChange={(e) => setUserData({ ...userData, email: e.target.value })}
									/>

									<span className="absolute right-4 top-4">
										<EnvelopeIcon />
									</span>
								</div>
								{
									userRegistration?.errors?.email && <p className='text-sm text-danger'>{ userRegistration?.errors?.email }</p>
								}
							</div>

							<div className="mb-6">
								<label className="mb-2.5 block font-medium text-black dark:text-white">
									Password
								</label>
								<div className="relative">
									<input
										type="password"
										placeholder="Enter a password"
										className="w-full rounded-lg border border-stroke py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
										value={password}
  										onChange={(e) => setUserData({ ...userData, password: e.target.value })}
									/>

									<span className="absolute right-4 top-4">
										<LockIcon />
									</span>
								</div>
								{
									userRegistration?.errors?.password && <p className='text-sm text-danger'>{ userRegistration?.errors?.password }</p>
								}
							</div>

							<div className="mb-6">
								<label className="mb-2.5 block font-medium text-black dark:text-white">
									Confirm Password
								</label>
								<div className="relative flex items-center">
									<input
										type={showPassword ? "text" : "password"}
										placeholder="Enter a password"
										className="w-full rounded-lg border border-stroke py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
										value={confirm_password}
  										onChange={(e) => setUserData({ ...userData, confirm_password: e.target.value })}
									/>

									<button type='button' className="absolute right-4  cursor-pointer text-[#8E929A] text-xl flex items-center outline-none" onClick={(e) => {
										e.preventDefault();
										setShowPassword((prev) => !prev)
									}}>
										{
											showPassword ? <FiEyeOff /> : <FiEye />
										}
									</button>
								</div>
							</div>
							<div className="mb-6">
								<label className="mb-2.5 block font-medium text-black dark:text-white">
									Date of Birth
								</label>
								<div className="relative bg-white rounded-lg">
									<DatePicker
										format="yyyy-MM-dd"
										value={userData.dob}
										onChange={(date) => setUserData({ ...userData, dob: date })}
										className='w-full rounded-lg border border-stroke py-4 pl-6 pr-3 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary'
										maxDate={new Date()}
										showMonthDropdown
										showYearDropdown
									/>
								</div>
								{
									userRegistration?.errors?.dob && <p className='text-sm text-danger'>{ userRegistration?.errors?.dob }</p>
								}
							</div>
							<div className="mb-6">
								<label className="mb-2.5 block font-medium text-black dark:text-white">
									Gender
								</label>
								<div className="flex">
									<div className='w-1/2 flex gap-3 items-center'>
										<label className="block font-medium text-black dark:text-white" htmlFor='male'>
											Male
										</label>
										<input type='radio' id='male' name='gender' onChange={(e) => {setUserData({ ...userData, gender: 'male' })}} checked={userData.gender === 'male'}/>
									</div>
									<div className='w-1/2 flex gap-3 items-center'>
										<label className="block font-medium text-black dark:text-white" htmlFor='female'>
											Female
										</label>
										<input type='radio' id='female' name='gender' onChange={(e) => {setUserData({ ...userData, gender: 'female' })}} checked={userData.gender === 'female'}/>
									</div>
								</div>
								{
									userRegistration?.errors?.gender && <p className='text-sm text-danger'>{ userRegistration?.errors?.gender }</p>
								}
							</div>
							<div className="mb-6">
								<label className="mb-2.5 block font-medium text-black dark:text-white">
									Bio
								</label>
								<div className="relative">
									<textarea className='w-full rounded-lg border border-stroke py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary' value={userData.bio} onChange={(e) => {
										setUserData({ ...userData, bio: e.target.value})
									}}>

									</textarea>
								</div>
								{
									userRegistration?.errors?.bio && <p className='text-sm text-danger'>{ userRegistration?.errors?.bio }</p>
								}
							</div>
							<div className='mb-6'>
								<label className="mb-3 block text-sm font-medium text-black dark:text-white">
									Select State
								</label>
								<div className="relative bg-white rounded-lg dark:bg-form-input">
									<Select
										classNames="basic-single"
										classNamePrefix="select"
										isSearchable={true}
										name="state"
										options={
											Object.keys(cities).map((state) => ({
												value: state,
												label: state
											}))
										}
										value={{ 
											value: userData.state,
											label: userData.state
										}}
										onChange={(e) => setUserData({ ...userData, state: e.value, city: "" })}
									/>
								</div>
							</div>
							<div className='mb-6'>
								<label className="mb-3 block text-sm font-medium text-black dark:text-white">
									Select City
								</label>
								<div className="relative bg-white rounded-lg dark:bg-form-input">
									<Select
										classNames="basic-single"
										classNamePrefix="select"
										isSearchable={true}
										name="city"
										options={
											userData.state ? cities[userData.state].map((city, index) => ({ 
												value: city,
												label: city
											})) : []
										}
										value={{ 
											value: userData.city,
											label: userData.city
										}}
										onChange={(e) => setUserData({ ...userData, city: e.value })}
									/>
								</div>
							</div>
							{userRegistration.successMessage && (
								<p className="text-success text-xl text-center">{userRegistration.successMessage}</p>
							)}
							<div className="mb-5">
								<input
									type="submit"
									value="Join"
									className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
								/>
							</div>
						</form>
						<div className="mt-6 text-center"><p>Already have an account? <Link className="text-primary" to="/login">Log in</Link></p></div>
					</div>
				</div>
			</div>
			<Loader loading={userRegistration?.loading} />
		</div>
	)
}

export default Register