// userActions.js
import axios from 'axios';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_FAILURE = 'VERIFY_TOKEN_FAILURE';

export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";
export const ONLINE_FRIENDS = "ONLINE_FRIENDS";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const loginUser = (loginData) => {
    return async (dispatch) => {
        dispatch({ type: LOGIN_USER_REQUEST });

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, loginData);

            if (response.data.message) {
                dispatch({ type: LOGIN_USER_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: LOGIN_USER_FAILURE, payload: response.data.errors });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: LOGIN_USER_FAILURE, payload: error.response.data });
            } else {
                dispatch({ type: LOGIN_USER_FAILURE, payload: 'An error occurred during login.' });
            }
        }
    };
};

export const registerUser = (userData) => {
    return async (dispatch) => {
        dispatch({ type: REGISTER_USER_REQUEST });
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/register`, userData);
    
            if (response.data.message) {
                dispatch({ type: REGISTER_USER_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
             dispatch({ type: REGISTER_USER_FAILURE, payload: response.data.errors });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: REGISTER_USER_FAILURE, payload: error.response.data });
            } else {
                dispatch({ type: REGISTER_USER_FAILURE, payload: 'An error occurred during registration.' });
            }
        }
    };
};

export const userProfile = () => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: USER_PROFILE_REQUEST });

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data) {
                dispatch({ type: USER_PROFILE_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: USER_PROFILE_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch({ type: USER_PROFILE_FAILURE, payload: error.response.message });
            } else {
                dispatch({ type: USER_PROFILE_FAILURE, payload: 'An error occurred during login.' });
            }
        }
    };
};

export const verifyToken = (token) => {
    return async (dispatch) => {
        dispatch({ type: VERIFY_TOKEN_REQUEST });

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/verify/${token}`);

            if (response.data) {
                dispatch({ type: VERIFY_TOKEN_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: VERIFY_TOKEN_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: VERIFY_TOKEN_FAILURE, payload: error.response.data.message });
            } else {
                dispatch({ type: VERIFY_TOKEN_FAILURE, payload: 'An error occurred during login.' });
            }
        }
    };
};

export const notificationCount = (notification_count) => {
    return async (dispatch) => {
        dispatch({ type: NOTIFICATION_COUNT, payload: {notification_count}});
    }
}

export const onlineFriends = (friends) => {
    return async (dispatch) => {
        dispatch({ type: ONLINE_FRIENDS, payload: {friends}});
    }
}

export const userUpdate = (userData, successCallback) => {
    const token = localStorage.getItem('access_token');
    
    return async (dispatch) => {
        dispatch({ type: UPDATE_USER_REQUEST });

        const array_keys = ['active_image_urls'];

        const formData = new FormData();
        Object.keys(userData).forEach((key) => {
            if(!array_keys.includes(key)){
                formData.append(key, userData[key]);
            }
        });

        if(userData?.images?.length > 0){
            userData.images.forEach((image) => {
                formData.append('images', image, image.name);
            });
        }

        array_keys.forEach((key) => {
            if(userData[key].length > 0){
                userData[key].forEach((value) => {
                    formData.append(`${key}[]`, value);
                })
            }
        })
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/update`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
    
            if (response.data.message) {
                if(successCallback){
                    successCallback(response.data.user);
                }
                dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
             dispatch({ type: UPDATE_USER_FAILURE, payload: response.data.errors });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: UPDATE_USER_FAILURE, payload: error.response.data });
            } else {
                dispatch({ type: UPDATE_USER_FAILURE, payload: 'An error occurred during registration.' });
            }
        }
    };
};

export const userUpdateLocation = (location, successCallback) => {
    const token = localStorage.getItem('access_token');
    
    return async (dispatch) => {
        dispatch({ type: UPDATE_USER_REQUEST });

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/update-location`, location, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.message) {
                if(successCallback){
                    successCallback(response.data.user);
                }
                dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
             dispatch({ type: UPDATE_USER_FAILURE, payload: response.data.errors });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: UPDATE_USER_FAILURE, payload: error.response.data });
            } else {
                dispatch({ type: UPDATE_USER_FAILURE, payload: 'An error occurred during registration.' });
            }
        }
    };
};